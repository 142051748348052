<template>
    <a-spin :spinning="spinning">
        <div class="modal-header">
            <div class="modal-title">
                <span v-if="id">{{ l('Edit') }}</span>
                <span v-if="!id">{{ l('Create') }}</span>
            </div>
        </div>
        <a-form :form="form" @submit="handleSubmit" layout="horizontal">
            <a-row :gutter="24">
                <a-col span="12">
                    <!-- 项目名称 -->
                    <a-form-item :label="l('ProjectHeaderName')">
                        <a-select :placeholder="l('ProjectHeaderName')"
                            :disabled="!!id === true"
                            v-decorator="['ProjectId',{ 
                                rules: [
                                    {
                                        required: true,
                                        message: l('RequiredProjectName'),
                                    }
                                ]
                            }]" @change="ProjectChange">
                            <a-select-option v-for="item in ProjectData" :key="item.id" :value="item.id">{{item.projectName}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!-- 项目编码 -->
                    <a-form-item :label="l('ProjectHeaderCode')">
                        <a-input disabled
                            :placeholder="l('ProjectHeaderCode')"
                            v-decorator="[
                                'ProjectCode',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: l('RequiredProjectCode'),
                                        }
                                    ],
                                },
                                ]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="12">
                    <!-- 人员 -->
                    <a-form-item :label="l('NameSurname')">
                        <a-select :placeholder="l('NameSurname')"
                            :disabled="!!id === true"
                            v-decorator="['UserId',{ 
                                rules: [
                                    {
                                        required: true,
                                        message: l('RequiredProjectUser'),
                                    }
                                ]    
                            }]" @change="UserChange">
                            <a-select-option v-for="item in UserData" :key="item.id" :value="item.id">{{item.realName}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!--身份证号-->
                    <a-form-item :label="l('ProjectUserIdCard')">
                        <a-input disabled :placeholder="l('ProjectUserIdCard')"
                            v-decorator="['icCard',
                                        { 
                                            rules: [] 
                                        }]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="12">
                    <!--手机号-->
                    <a-form-item :label="l('ProjectUserPhone')">
                        <a-input disabled
                            :placeholder="l('ProjectUserPhone')"
                            v-decorator="['Phone',{ rules: [] }]" />
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!--日期-->
                    <a-form-item :label="l('ProjectHeaderTime')">
                        <a-range-picker :placeholder="['开始日期', '结束日期']" valueFormat="YYYY-MM-DD" v-decorator="['ProjectTime', 
                        { 
                            rules: [
                                {
                                    required: true,
                                    message: l('RequiredProjectTime')
                                }
                            ]
                        }]">
                            <template slot="dateRender" slot-scope="current">
                            <div class="ant-calendar-date">
                                {{ current.date() }}
                            </div>
                            </template>
                        </a-range-picker>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="24">
                    <!--备注-->
                    <a-form-item :label="l('ProjectRemark')">
                        <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }"
                            :placeholder="l('ProjectRemark')"
                            v-decorator="['Remark',{ rules: [] }]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="24">
                    <a-form-item class="btn--container" style="text-align:center;">
                        <a-button type="button" @click="close()">
                            {{ l("Cancel") }}
                        </a-button>
                        <a-button type="primary" html-type="submit">
                            {{ l('Save') }}
                        </a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
    </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { setInterval, setTimeout } from 'timers';

export default {
    mixins: [ModalComponentBase],
    name: "create-or-edit-project",
    data() {
        return {
            // 获取到的数据
            entity: {},
            spinning: false,
            ProjectData: [],
            UserData: [],
            Enabled: false
        };
    },
    components: {},
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: "register" });
    },
    created() {
        this.fullData(); // 模态框必须,填充数据到data字段
    },
    mounted() {
        this.getData();
        this.getAllUser();
        this.getAllProject();
    },
    methods: {
        /**
         * 获取数据
         */
        getData() {
          if(this.id && this.id > 0) {
            this.spinning = true;
            let url = this.$apiUrl + '/api/services/app/ProjectUser/GetById';
            let options = {
              params: {
                id: this.id
              },
              method: "GET",
              url: url,
              headers: {
                "Content-Type": "application/json-patch+json",
              }
            };
            this.$api.request(options).then(response => {
              let res = response.data;
              if (!res) {
                return false;
              }
              this.entity = res;
              this.form.setFieldsValue({
                  ProjectId: this.entity.projectId,
                  ProjectCode: this.entity.projectCode,
                  UserId: this.entity.userId,
                  ProjectTime: [res.beginDate, res.endDate],
                  Remark: res.remark,
                  icCard: res.icCard,
                  Phone: res.phone,
                  Id: this.entity.Id
              });
              this.Enabled = res.hasSigned;
            }).catch(e => {
              console.error(e);
              abp.message.warn(this.l("DefaultErrorMessage"));
            }).finally(() => {
              this.spinning = false;
            });
          }
        },
        /**
         * 提交表单
         */
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    this.spinning = true;
                    let dto = {
                      UserId: values.UserId,
                      ProjectId: values.ProjectId,
                      BeginDate: values.ProjectTime[0],
                      EndDate: values.ProjectTime[1],
                      Remark: values.Remark
                    };
                    if (this.entity && this.entity.id) {
                        dto.Id = this.entity.id;
                    }else{
                      dto.auditStatus = 1;
                    }
                    let url = this.$apiUrl + '/api/services/app/ProjectUser/CreateOrUpdate';
                    let options = {
                      data: dto,
                      method: "POST",
                      url: url,
                      headers: {
                        "Content-Type": "application/json-patch+json",
                      }
                    };
                    this.$api.request(options).finally(() => {
                            this.spinning = false;
                        }).then(res => {
                            this.$notification["success"]({
                                message: this.l("SavedSuccessfully")
                            });
                            this.success(true);
                        }).catch(err => {
                            console.log(err);
                        });
                }
            });
        },
        getAllUser() {
            let url = this.$apiUrl + '/api/services/app/Personnel/GetPaged';
            let options = {
              params: {
                maxResultCount: 1000,
                skipCount: 0
              },
              method: "GET",
              url: url,
              headers: {
                "Content-Type": "application/json-patch+json",
              }
            };
            this.$api.request(options).then(response => {
              let res = response.data;
              this.UserData = res.items;
            }).catch(e => {
              console.error(e);
              abp.message.warn(this.l("DefaultErrorMessage"));
            });
        },
        getAllProject() {
          let url = this.$apiUrl + '/api/services/app/Project/GetProjectPaged';
          let options = {
            params: {
              maxResultCount: 1000,
              skipCount: 0
            },
            method: "GET",
            url: url,
            headers: {
              "Content-Type": "application/json-patch+json",
            }
          };
          this.$api.request(options).then(response => {
            let res = response.data;
            this.ProjectData = res.items;
          }).catch(e => {
            console.error(e);
            abp.message.warn(this.l("DefaultErrorMessage"));
          });
        },
        ProjectChange(value, e) {
          if (value) {
            for (let i = 0; i < this.ProjectData.length; i++) {
              let item = this.ProjectData[i];
              if (item.id === value) {
                this.form.setFieldsValue({
                  ProjectCode: item.projectCode
                });
                break;
              }
            }
          }
        },
        UserChange(value, e) {
          if (value) {
            for(let i = 0; i < this.UserData.length; i++) {
              let item = this.UserData[i];
              if (value === item.id) {
                this.form.setFieldsValue({
                  icCard: item.idCard,
                  Phone: item.phoneNumber
                });
                break;
              }
            }
          }
        }
    }
};
</script>

<style lang="less" scoped>
.btn--container .ant-form-item-children {
    display: block;
    text-align: center;
}
.pleaseSelect-text {
    font-size: 14px;
    padding: 0 14px;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 0;
}
</style>
