import 'rxjs/add/operator/finally';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CommonProcess } from "@/shared/common-process";

const cp = new CommonProcess()

export class PersonnelAgreementServiceProxy {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  /**
   * 读取列表
   * @param projectId 
   */
  getListByProjectId(projectId: string): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/ProjectAgreement/GetListByProjectId?`;
    if (projectId === null) {
      throw new Error("The parameter 'projectId' cannot be null.");
    } else if (projectId !== undefined) {
      url_ += "projectId=" + encodeURIComponent("" + projectId) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 读取签署主体配置列表
   * @param projectAgreementId 
   */
  getSigningBodyConfigurations(projectAgreementId: string): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/ProjectAgreement/GetSigningBodyConfigurations?`;
    if (projectAgreementId === null) {
      throw new Error("The parameter 'agreementTemplateId' cannot be null.");
    } else if (projectAgreementId !== undefined) {
      url_ += "projectAgreementId=" + encodeURIComponent("" + projectAgreementId) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 新增
   * @param body 
   */
  insert(body: any | undefined): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/ProjectAgreement/Insert`;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 是否可签署
   * @param body 
   */
  canSign(body: any | undefined): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/ProjectAgreement/CanSign`;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }


  /**
   * 获取单条
   * @param id 
   */
  agreementSigningMethodGetEditById(id: string): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/AgreementSigningMethod/GetEditById?`;
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 更新
   * @param body 
   */
  update(body: any | undefined): Promise<void> {
    let url_ =
      this.baseUrl + "/api/services/app/ProjectAgreement/Update";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(body);

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "PUT",
      url: url_,
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 更新签署主体配置
   * @param body 
   */
  updateSigningBodyConfiguration(body: any | undefined): Promise<void> {
    let url_ =
      this.baseUrl + "/api/services/app/ProjectAgreement/UpdateSigningBodyConfiguration";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(body);

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "PUT",
      url: url_,
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  agreementTemplateGetSelectData(): Promise<void> {
    let url_ =
      this.baseUrl + "/api/services/app/AgreementTemplate/GetSelectData";
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }


  getTopOrganizationUnitOption(): Promise<void> {
    let url_ =
      this.baseUrl + "/api/services/app/OrganizationUnit/GetTopOrganizationUnitOption";
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  getCustomerOption(): Promise<void> {
    let url_ =
      this.baseUrl + "/api/services/app/Customer/GetCustomerOption";
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  canSetAutoSignForCustomer(customerId: string): Promise<void> {
    let url_ =
      this.baseUrl + "/api/services/app/ProjectAgreement/CanSetAutoSignForCustomer?";
    if (customerId === null) {
      throw new Error("The parameter 'customerId' cannot be null.");
    } else if (customerId !== undefined) {
      url_ += "customerId=" + encodeURIComponent("" + customerId) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  canSetAutoSignForOrganization(orgId: string): Promise<void> {
    let url_ =
      this.baseUrl + "/api/services/app/ProjectAgreement/CanSetAutoSignForOrganization?";
    if (orgId === null) {
      throw new Error("The parameter 'orgId' cannot be null.");
    } else if (orgId !== undefined) {
      url_ += "orgId=" + encodeURIComponent("" + orgId) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  

}
