<template>
  <a-spin :spinning="spinning">
    <section>
      <div class="modal-header">
        <div class="modal-title">
          <a-icon type="unordered-list" />查看签署记录
        </div>
      </div>

      <ele-table ref="rt" :table-data="tableData" :actions-type="actionsType" :columns="columns" :is-full="false"
        :hide-row-selection="false" :hide-pagination="true" @emitRefreshData="clearFilterAndRefresh"
        @emitSelectChange="selectChange">
        <!-- 按钮 -->
        <a-row :gutter="8" class="" type="flex">
          <a-col class="gutter-row" :span="8">
            <a-input v-model="filterText" :placeholder="l('SearchWithThreeDot')" />
          </a-col>
          <a-col class="gutter-row" :span="11">
            <a-button type="primary" @click="getData">
              {{ l("Search") }}
            </a-button>
            <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
          </a-col>
          <a-col class="gutter-row" :span="5" align="right">
            <a-button type="primary" @click="notification()">
              <a-icon type="notification" />
              {{ l("通知签署") }}
            </a-button>
            <a-button type="primary" @click="emailNotification()">
              <a-icon type="notification" />
              {{ l("邮箱通知") }}
            </a-button>
          </a-col>
        </a-row>
      </ele-table>

      <div class="modal-footer">
        <a-button :disabled="saving" @click="close()" type="button">
          <a-icon type="close-circle" />
          {{ l("关闭") }}
        </a-button>
        <a-button :loading="saving" :type="'primary'" @click="addTemplete">
          <a-icon type="plus" />
          {{ l("添加") }}
        </a-button>
      </div>
    </section>

    <a-modal centered :title="seclectModalParams.title" :visible="seclectModalParams.visible"
      :confirm-loading="seclectModalParams.confirmLoading" :cancelText="seclectModalParams.cancelText"
      :okText="seclectModalParams.okText" :maskClosable="seclectModalParams.maskClosable"
      :destroyOnClose="seclectModalParams.destroyOnClose" :width="seclectModalParams.width"
      @ok="seclectModalParams.confirm" @cancel="seclectModalParams.cancel">
      <div>
        <a-form :form="form1" v-bind="formItemLayout">
          <a-form-item label="协议模版" has-feedback>
            <a-select v-decorator="[
              'projectAgreementId',
              { rules: [{ required: true, message: '请选择协议模板' }] },
            ]" placeholder="请选择">
              <a-select-option v-for="(item, index) in seclectModalParams.selectList" :key="index" :value="item.value">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>

    <a-modal centered :title="inputModalParams.title" :visible="inputModalParams.visible"
      :confirm-loading="inputModalParams.confirmLoading" :cancelText="inputModalParams.cancelText"
      :okText="inputModalParams.okText" :maskClosable="inputModalParams.maskClosable"
      :destroyOnClose="inputModalParams.destroyOnClose" :width="inputModalParams.width" @ok="inputModalParams.confirm"
      @cancel="inputModalParams.cancel">
      <div>
        <a-form :form="form2" v-bind="formItemLayout">
          <a-form-item label="作废原因" has-feedback>
            <a-input type="textarea" :placeholder="l('请输入')" v-decorator="[
              'invalidReason',
              {
                rules: [
                  { required: true, message: this.l('ThisFieldIsRequired') },
                ],
              },
            ]" />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </a-spin>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { PersonnelAgreementServiceProxy } from "../services/personnel-agreement-proxies";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import EleTable from "@/components/ele-table";
// import ConfigList from "./config-list";
import EmailNotifications from "@/components/react-emails";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import { abpService } from "@/shared/abp";
import checkAgeFunc from '@/components/PersonAgeTip/check.js';

export default {
  name: "agreement-list",
  mixins: [AppComponentBase, ModalComponentBase],
  components: { EleTable },
  data () {
    return {
      filterText: "",
      spinning: false,
      // 表单
      formLayout: "horizontal",
      form1: this.$form.createForm(this, { name: "coordinated" }),
      form2: this.$form.createForm(this, { name: "coordinated" }),
      isDisabled: false,

      tableKey: "",
      commonService: null,
      mobileService: null,
      personnelService: null,

      tableData: [],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      actionsType: {},
      signStatuList: ["未签署", "单方签署", "已签署", "归档", "已通知"],
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      seclectModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "",
        form: {},
        id: null,
        selectList: [],
        cancelText: "取消",
        okText: "确认",
        confirm: () => { },
        cancel: () => {
          this.seclectModalParams.visible = false;
        },
      },
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "",
        form: {},
        id: null,
        selectList: [],
        cancelText: "取消",
        okText: "确认",
        confirm: () => { },
        cancel: () => {
          this.inputModalParams.visible = false;
        },
      },
      // 用户表格
      columns: [
        {
          title: this.l("模板名称"),
          dataIndex: "agreementTemplateName",
          // sorter: true,
          align: "center",
          width: 150,
          fixed: "left",
          scopedSlots: { customRender: "agreementTemplateName" },
        },
        {
          title: this.l("签署状态"),
          dataIndex: "signStatusStr",
          // sorter: true,
          align: "center",
          width: 100,
          scopedSlots: { customRender: "signStatusStr" },
        },
        {
          title: this.l("状态"),
          dataIndex: "isInvalid",
          // sorter: true,
          align: "center",
          type: "tag",
          width: 100,
          scopedSlots: { customRender: "isInvalid" },
          customRender: (text) => {
            return text ? "已作废" : "正确";
            // return text ? (
            //   <a-tag color="#f50">已作废</a-tag>
            // ) : (
            //   <a-tag color="#87d068">正常</a-tag>
            // );
          },
        },
        {
          title: this.l("合同编号"),
          dataIndex: "contractNo",
          sorter: false,
          width: 290,
          align: "center",
          scopedSlots: { customRender: "contractNo" },
          customRender: (text) => {
            if (text) {
              return text;
            } else {
              return "-";
            }
          },
        },
        // {
        //   title: this.l("合同流水号"),
        //   dataIndex: "transactionNo",
        //   // sorter: true,
        //   align: "center",
        //   scopedSlots: { customRender: "transactionNo" },
        // },
        {
          title: this.l("签署时间"),
          dataIndex: "signTimeStr",
          // sorter: true,
          align: "center",
          width: 200,
          scopedSlots: { customRender: "signTimeStr" },
        },
        {
          title: this.l("创建人"),
          dataIndex: "creatorUserName",
          // sorter: true,
          align: "center",
          width: 100,
          scopedSlots: { customRender: "creatorUserName" },
        },
        {
          title: this.l("创建时间"),
          dataIndex: "creationTimeStr",
          // sorter: true,
          align: "center",
          width: 200,
          scopedSlots: { customRender: "creationTimeStr" },
        },
        {
          title: this.l("拒签时间"),
          dataIndex: "rejectTime",
          // sorter: true,
          align: "center",
          width: 100,
          scopedSlots: { customRender: "rejectTime" },
        },
        {
          title: this.l("作废时间"),
          dataIndex: "invalidTimeStr",
          // sorter: true,
          align: "center",
          width: 200,
          scopedSlots: { customRender: "invalidTimeStr" },
        },
        {
          title: this.l("作废人"),
          dataIndex: "invalidUserName",
          // sorter: true,
          align: "center",
          width: 100,
          scopedSlots: { customRender: "invalidUserName" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          // width: 100,
          // sorter: true,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
      selectedRowKeys: [],
      selectedRows: [],
      fieldsList: [],
    };
  },
  created () {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.mobileService = new CommonServiceProxy(
      AppConsts.mobileApiBaseUrl,
      this.$api
    );
    this.personnelService = new PersonnelAgreementServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.fullData(); // 模态框必须,填充数据到data字段

    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
    this.initActionsType();
  },
  mounted () {
    if (this.params) {
      this.$nextTick(() => { });
    }
    this.getData();
    this._agreementTemplateGetSelectData();
  },
  methods: {
    getData () {
      this._getProjectPersonnelAgreement();
    },
    async _handAutoSign (eleAgreementId) {
      try {
        this.spinning = true;
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/HandAutoSign",
          params: {
            id: eleAgreementId,
          },
        });
        this.$notification["success"]({
          message: "手动盖章完成",
        });
        this.spinning = false;
      } catch (err) {
        this.spinning = false;
      }
    },
    async _getProjectPersonnelAgreement () {
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      });
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/GetList",
          params: {
            personnelId: this.personnelAgreementItem.userId,
            projectId: this.personnelAgreementItem.projectId,
            tempName: this.filterText,
          },
        });
        this.tableData = res.map((item) => {
          return {
            ...item,
            signTimeStr: item.signTime
              ? moment(item.signTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            creationTimeStr: item.creationTime
              ? moment(item.creationTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            invalidTimeStr: item.invalidTime
              ? moment(item.invalidTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            signStatusStr: this.signStatuList[item.signStatus],
            isInvalid: item.isInvalid ? "已作废" : "正常",
            isInvalidColor: item.isInvalid ? "#f50" : "#87d068",
          };
        });
        this.totalItems;
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async _agreementTemplateGetSelectData () {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/GetSelectData",
          params: {
            projectId: this.personnelAgreementItem.projectId,
          },
        });
        this.spinning = false;
        this.seclectModalParams.selectList = res;
      } catch (error) {
        this.spinning = false;
      }
    },
    addTemplete () {
      let _this = this;
      this.seclectModalParams.title = "选择协议模版";
      this.seclectModalParams.confirm = function () {
        _this.form1.validateFields(async (err, values) => {
          if (!err) {
            _this.seclectModalParams.confirmLoading = true;
            try {
              const params = {
                projectAgreementId: values.projectAgreementId,
                personnelIds: [_this.personnelAgreementItem.userId]
              };
              await checkAgeFunc(params, (res) => {
                if (res) {
                  _this.realAddAgreementToSign(values);
                }
              });
            } catch (e) {
              console.error(e);
            } finally {
              _this.seclectModalParams.confirmLoading = false;
            }
          }
        });
      };
      this.seclectModalParams.visible = true;
    },
    async realAddAgreementToSign (values) {
      this.seclectModalParams.confirmLoading = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/Insert",
          params: {
            ...values,
            personnelId: this.personnelAgreementItem.userId,
          },
        });
        this.seclectModalParams.confirmLoading = false;
        this.seclectModalParams.visible = false;
        this._getProjectPersonnelAgreement();
      } catch (error) {
        console.log(error);
        this.seclectModalParams.confirmLoading = false;
      }
    },
    editTemplete (item) {
      console.log(item);
      let _this = this;
      this.seclectModalParams.visible = true;
      this.seclectModalParams.title = "修改协议模版";
      setTimeout(() => {
        this.form1.setFieldsValue({
          projectAgreementId: item.projectAgreementId.toUpperCase(),
        });
      }, 200);

      this.seclectModalParams.confirm = function () {
        _this.form1.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.seclectModalParams.confirmLoading = true;
            try {
              let res = await _this.commonService.post({
                url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/UpdateProjectAgreementId",
                params: {
                  ...values,
                  id: item.id,
                },
              });
              _this.seclectModalParams.confirmLoading = false;
              _this.seclectModalParams.visible = false;
              _this._getProjectPersonnelAgreement();
            } catch (error) {
              console.log(error);
              _this.seclectModalParams.confirmLoading = false;
            }
          }
        });
      };
    },
    setInvalid (projectAgreementId) {
      let _this = this;
      this.inputModalParams.title = "填写作废原因";
      this.inputModalParams.confirm = function () {
        _this.form2.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.inputModalParams.confirmLoading = true;
            try {
              let _res = await _this.commonService.get({
                url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/Invalid",
                params: {
                  projectAgreementId: projectAgreementId,
                  invalidReason: values.invalidReason,
                },
              });
              _this.inputModalParams.confirmLoading = false;
              _this.inputModalParams.visible = false;
              _this._getListByProjectId();
            } catch (error) {
              _this.inputModalParams.confirmLoading = false;
            }
          }
        });
      };
      this.inputModalParams.visible = true;
    },
    async invalidPersonnelAgreement (id) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/Invalid",
          params: {
            id: id,
          },
        });
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: this.l("作废成功."),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh () {
      this.refreshGoFirstPage();
    },
    initActionsType () {
      let _this = this;
      let obj = {
        type: "templete-list",
        isShow: true,
        fns: {
          detailList: [
            {
              granted: true,
              name: this.l("盖章"),
              icon: "book",
              fn: (data) => {
                if (data.electronicAgreementId == null) {
                  this.$message.error("用户还未发起签署，无法盖章");
                  return;
                }
                abp.message.confirm("是否确定盖章？", "提示", (result) => {
                  if (result) {
                    _this._handAutoSign(data.electronicAgreementId);
                  }
                });
              },
            },
          ],
          dropList: [
            {
              granted: true,
              name: this.l("Edit"),
              icon: "edit",
              fn: (data) => {
                // _this.createOrEdit(data.id, 'edit');
                _this.editTemplete(data);
              },
            },
            {
              granted: false,
              name: this.l("删除"),
              icon: "delete",
              fn: (data) => {
                console.log(data);
                _this.delPersonnelAgreement(data.id);
              },
            },
            {
              granted: true,
              name: this.l("作废"),
              icon: "delete",
              fn: (data) => {
                console.log(data)
                if (data.isInvalid === '已作废') {
                  this.$message.error("当前状态已作废");
                  return;
                }
                abp.message.confirm("是否确定作废？", "提示", (result) => {
                  if (result) {
                    _this.invalidPersonnelAgreement(data.id);
                  }
                });
              },
            },
            {
              granted: true,
              name: this.l("查看协议"),
              icon: "eye",
              fn: (data) => {
                console.log(data);
                if (data.viewUrl) {
                  window.open(data.viewUrl);
                } else {
                  // _this.$message.info("暂无协议");
                  _this._getSignDataSource(data);
                }
              },
            },
            {
              granted: true,
              name: this.l("下载协议"),
              icon: "download",
              fn: (data) => {
                if (data.downloadUrl) {
                  window.location.href = data.downloadUrl;
                } else {
                  _this.$message.info("暂无协议");
                }
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
    createOrEdit (id) {
      ModalHelper.create(
        ConfigList,
        {
          projectAgreementId: id,
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        this._getListByProjectId();
      });
    },
    /**
     * 重置
     */
    refreshGoFirstPage () {
      this.filterText = "";
      this.getData();
    },
    delPersonnelAgreement (id) {
      let _that = this;
      this.message.confirm("是否确定删除", (res) => {
        if (res) {
          _that.delData(id);
        }
      });
    },
    async delData (id) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/Delete",
          params: {
            id: id,
          },
        });
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: this.l("SuccessfullyDeleted"),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    notification () {
      console.log(this.selectedRowKeys);
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "通知签署"));
        return;
      }
      abp.message.confirm("是否要通知签署？", "系统提示", (res) => {
        if (res) {
          this.notificationData();
        }
      });
      // if (selectCount == 1) {
      //   this.notificationData();
      // } else {
      //   abp.message.confirm("是否批量通知签署？", "系统提示", (res) => {
      //     if (res) {
      //       this.notificationData();
      //     }
      //     // if (res) {
      //     //   this.batchNotificationData();
      //     // } else {
      //     //   this.notificationData();
      //     // }
      //   });
      // }
    },
    emailNotification () {
      let ids = this.selectedRowKeys.map((item) => {
        return item.id;
      });
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "发送邮件"));
        return;
      }
      ModalHelper.create(
        EmailNotifications,
        {
          params: {
            url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/EmailNotifications",
            ids: ids,
          },
        },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    async notificationData () {
      this.spinning = true;
      let projectAgreementIdArr = this.selectedRowKeys.map((item) => {
        return item.projectAgreementId;
      });
      console.log(projectAgreementIdArr);
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/SendSignNotice",
          params: {
            personnelId: this.personnelAgreementItem.userId,
            projectAgreementIds: projectAgreementIdArr,
          },
        });
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: res, //this.l("通知签署成功"),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async batchNotificationData () {
      this.spinning = true;
      let projectAgreementIdArr = this.selectedRowKeys.map((item) => {
        return item.projectAgreementId;
      });
      console.log(projectAgreementIdArr);
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/SendSignNotices",
          params: {
            personnelId: this.personnelAgreementItem.userId,
            projectAgreementIds: projectAgreementIdArr,
          },
        });
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: this.l("通知签署成功"),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    selectChange (data) {
      let { selectedRowKeys, selectedRows } = data;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowKeys = selectedRows;
    },
    async _getSignDataSource (data) {
      this.spinning = true;
      try {
        let res = await this.mobileService.get(
          {
            url: "/api/Unnatural/getSignDataSource",
            params: {
              projectAgreementId: data.projectAgreementId,
              personnelId: this.personnelAgreementItem.userId,
              projectPersonnelAgreementId: data.id,
            },
          },
          {
            WechatAppletTenantId:
              abpService.abp.multiTenancy.getTenantIdCookie() || "",
          }
        );
        if (res.success && res.statusCode === 200) {
          this.fieldsList = res.data;
        } else {
          this.$notification["warning"]({
            message: "提示",
            description: `${res.message}， 无法填充`,
          });
        }
        this._contractPreview(data);
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async _contractPreview (data) {
      this.spinning = true;
      this.fields = this.fieldsList.map((item) => {
        return {
          fieldValue: item.value,
          fieldDisplayName: item.key,
          fieldKey: item.key,
          // isCanEdit: this.isCanEdit(item.fieldKey),
          // fieldValue: f ? f.value : item.fieldValue,
        };
      });
      const opts = {
        userId: this.personnelAgreementItem.userId,
        templateGuid: data.agreementTemplateId,
        projectAgreementId: data.projectAgreementId,
        fields: [...this.fields],
      };
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/ContractPreviewV2",
          params: { ...opts },
        });
        console.log(res);
        window.open(`${AppConsts.uploadBaseUrl}${res}`);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    isCanEdit (type) {
      switch (type) {
        case "organization_name":
          return false;
        case "personnel_realname":
          return false;
        case "phone_number":
          return false;
        case "idcard":
          return false;
        default:
          return true;
      }
    },
  },
};
</script>

<style scoped lang="less">
// @import "../../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}

.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
</style>
