<template>
  <div>
    <div class="modal-header">
      <div class="modal-title" style="width: 100%; text-align: left">
        发起签署
      </div>
    </div>
    <a-steps :current="current">
      <a-step v-for="item in steps" :key="item.title" :title="item.title" />
    </a-steps>
    <div class="steps-content">
      <div v-if="current === 0">
        <div>
          <div style="margin-bottom: 10px">1. 选择项目</div>
          <div>
            <a-input
              style="width: 400px"
              v-model="form.projectName"
              placeholder="请选择项目"
              allow-clear
              @click="selectProjectModal"
            >
            </a-input>
            <a-input v-model="form.projectId" type="hidden"> </a-input> 
          </div>
        </div>
        <div style="margin-top: 15px">
          <div style="margin-bottom: 15px">2. 选择协议</div>
          <div class="x-ag">
            <a-spin :spinning="loading">
              <a-transfer
                :data-source="projectAgreements"
                :list-style="{
                  width: '280px',
                  height: '350px',
                  textAlign: 'left',
                }"
                :show-search="true"
                :filter-option="filterOption"
                :target-keys="projectAgreementTargetKeys"
                :render="(item) => item.title"
                :locale="{
                  itemUnit: '项',
                  itemsUnit: '项',
                  notFoundContent: '列表为空',
                  searchPlaceholder: '请输入协议名称',
                }"
                @change="handleChange"
                @search="handleSearch"
              />
            </a-spin>
          </div>
        </div>
      </div>
      <div v-if="current === 1">
        <div style="margin-bottom: 40px">
          <a-input
            style="width: 200px"
            v-model="form.filterText"
            placeholder="姓名/身份证号/手机号"
            allowClear
          >
          </a-input>
          <a-input
            style="width: 150px;margin-left: 10px;"
            v-model="form.authLabel"
            placeholder="人员标签"
            allowClear
          >
          </a-input>
          <a-select placeholder="签署情况" :default-value="agreementIsSent" style="width: 150px;margin-left: 10px;" @change="agreementIsSentChange" allowClear>
            <a-select-option value="1">
              已发协议
            </a-select-option>
            <a-select-option value="0">
              未发协议
            </a-select-option>
          </a-select>
          <a-button
            style="margin-left: 10px"
            type="primary"
            icon="search"
            @click="getPersonalsByProjectId"
            >搜索</a-button
          >
          <a-button
            style="margin-left: 10px"
            type="default"
            @click="importData"
            ><a-icon type="unordered-list" />批量人员筛选</a-button
          >
          <a-button
            style="margin-left: 10px"
            type="default"
            @click="clearFile"
            :disabled="!oFile"
            ><a-icon type="disconnect" />清空批量人员筛选</a-button
          >
        </div>
        <a-spin :spinning="loading">
          <a-transfer
            :list-style="{
              height: '390px',
              textAlign: 'left',
            }"
            :data-source="projectPersonals"
            :target-keys="projectPersonalTargetKeys"
            :locale="{
              itemUnit: '个员工',
              itemsUnit: '个员工',
              notFoundContent: '列表为空',
              searchPlaceholder: '请输入姓名',
            }"
            @change="onChange"
          >
            <template
              slot="children"
              slot-scope="{
                props: { filteredItems, selectedKeys, disabled: listDisabled },
                on: { itemSelectAll, itemSelect },
              }"
            >
              <a-table
                bordered
                :row-selection="
                  getRowSelection({
                    disabled: listDisabled,
                    selectedKeys,
                    itemSelectAll,
                    itemSelect,
                  })
                "
                :pagination="filteredItems&&filteredItems.length ? pagination : false"
                :scroll="{ y: 240 }"
                :columns="tableColumns"
                @change="handleTableChange"
                :data-source="filteredItems"
                size="small"
                :style="{ pointerEvents: listDisabled ? 'none' : null }"
                :custom-row="
                  ({ key, disabled: itemDisabled }) => ({
                    on: {
                      click: () => {
                        if (itemDisabled || listDisabled) {
                          return;
                        }
                        itemSelect(key, !selectedKeys.includes(key));
                      },
                    },
                  })
                "
              />
            </template>
          </a-transfer>
        </a-spin>
      </div>
      <div v-if="current === 2" style="min-height: 465px">
        <a-descriptions title="信息预览" bordered>
          <a-descriptions-item label="项目名称" :span="3">
            <div style="text-align: left">
              {{ form.projectName }}（{{ form.projectCode }}）
            </div>
          </a-descriptions-item>
          <a-descriptions-item label="协议列表" :span="3">
            <div style="text-align: left">
              <div
                style="margin-bottom: 10px"
                v-for="(item, index) in projectAgreementTargets"
                :key="index"
              >
                <a-badge
                  style="margin-right: 5px"
                  status="processing"
                  :text="item.title"
                />
                <a v-if="item.pdfTemplateUrl" :href="uploadBaseUrl + item.pdfTemplateUrl" target="_blank">
                  <span>点击预览模板</span>
                </a>
              </div>
            </div>
          </a-descriptions-item>
          <a-descriptions-item label="人员列表">
            <div style="width: 900px">
              <a-table
                :columns="tableColumns"
                :pagination="false"
                size="small"
                :data-source="projectPersonalTargets"
              ></a-table>
            </div>
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div v-if="current === 3"></div>
    </div>
    <div class="steps-action">
      <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">
        <a-icon type="double-left" />
        <span>上一步</span>
      </a-button>
      <a-button v-if="current < steps.length - 1" type="primary" @click="next">
        <span>下一步</span>
        <a-icon type="double-right" />
      </a-button>
      <div
        v-if="current == steps.length - 1"
        style="display: inline-block; margin-left: 10px"
      >
        <a-popconfirm
          title="确定要发起签署吗?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="sendSignNotice(false)"
        >
          <a-button type="primary" :loading="loading"> 发起签署 </a-button>
        </a-popconfirm>
        <a-popconfirm
          title="确定要发起签署并通知吗?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="sendSignNotice(true)"
        >
          <a-button :loading="loading" style="margin-left: 10px" type="default">
            发起签署并通知
          </a-button>
        </a-popconfirm>
      </div>
    </div>
  </div>
</template>
<script>
import difference from "lodash/difference";
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { ModalHelper } from "@/shared/helpers";
import SelectPageList from "@/components/linggong/select-page-list";
import { AppConsts } from "@/abpPro/AppConsts";
import { fileDownloadService } from "@/shared/utils";
import { Modal } from "ant-design-vue";
import ImportForm from "./filterImportform.vue";

export default {
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      form: {
        projectId: "",
        projectName: "",
        projectCode: "",
        authLabel: "",
        filterText: "",
      },
      agreementIsSent: undefined,
      agreementTemplateIds: [],
      uploadBaseUrl: AppConsts.uploadBaseUrl,
      current: 0,
      steps: [
        {
          title: "选择项目与协议",
        },
        {
          title: "选择人员",
        },
        {
          title: "预览发起",
        },
      ],
      commonService: undefined,
      projectAgreements: [],
      projectAgreementTargetKeys: [],
      projectAgreementTargets: [],
      tableColumns: [
        {
          dataIndex: "name",
          title: "姓名",
          width: 75,
        },
        {
          dataIndex: "phoneNumber",
          title: "手机号",
          width: 120,
        },
        {
          dataIndex: "idCard",
          title: "身份证号",
          width: 180,
        },
      ],
      projectPersonals: [],
      projectPersonalTargetKeys: [],
      projectPersonalTargets: [],
      pagination: {
        current: 1,
        pageSize: 50,
        showSizeChanger: true,
        pageSizeOptions: ['50', '100', '200', '400', '800']
      },
      oFile: null,
    };
  },
  mounted() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      // const pager = { ...this.pagination };
      // pager.current = pagination.current;
      // pager.pageSize = pagination.pageSize;
      this.pagination = {...pagination};
      this.projectPersonalTargetKeys = [];
      this.getPersonalsByProjectId();
    },
    // setp 3
    sendSignNotice(noticeOrNot) {
      this.loading = true;
      this.commonService
        .post({
          url: "/api/services/app/UnnaturalPersonProjectPersonnel/BatchNoticeSign",
          params: {
            noticeOrNot: noticeOrNot,
            personnelIds: this.projectPersonalTargetKeys,
            projectAgreementIds: this.projectAgreementTargetKeys,
          },
        })
        .then((res) => {
          this.loading = false;
          console.log(res);
          if (res.file) {
            fileDownloadService.downloadTempFile(res.file);
          }
          Modal.success({
            title: "系统提示",
            content: this.l(res.message),
            okText: "我知道了",
            onOk: () => {
              this.success();
            },
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // setp 2
    onChange(nextTargetKeys, direction, moveKeys) {
      this.projectPersonalTargetKeys = nextTargetKeys;
      let list = [];
      for (let i = 0; i < this.projectPersonalTargetKeys.length; i++) {
        const key = this.projectPersonalTargetKeys[i];
        const res = this.projectPersonals.find((p) => p.key === key);
        if (res) {
          list.push(res);
        }
      }
      this.projectPersonalTargets = list;
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      // console.log(disabled, selectedKeys, itemSelectAll, itemSelect);
      return {
        getCheckboxProps: (item) => ({
          props: { disabled: disabled || item.disabled },
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },
    // 读取项目人员列表
    async getPersonalsByProjectId() {
      this.loading = true;
      let url =
        this.$apiUrl +
        "/api/services/app/UnnaturalPersonProjectPersonnel/GetPaged1";
      const { current, pageSize } = this.pagination;
      let skipCount = (current - 1) * pageSize;

      var d = new FormData();
      d.append("skipCount", skipCount);
      d.append("maxResultCount", pageSize);
      d.append("projectId", this.form.projectId);
      d.append("filterText", this.form.filterText);
      d.append("authLabel", this.form.authLabel);
      if (this.agreementIsSent && this.agreementIsSent !== undefined) {
        d.append("agreementIsSent", this.agreementIsSent === '1');
      }
      d.append("projectAgreementIds", this.projectAgreementIds);
      d.append("authLabel", this.form.authLabel);

      if (this.oFile) {
        d.append("file", this.oFile);
      }

      let options = {
        // data: {
        //   skipCount,
        //   maxResultCount: pageSize,
        //   projectId: this.form.projectId,
        //   filterText: this.form.filterText,
        //   authLabel: this.form.authLabel,
        //   agreementIsSent: this.agreementIsSent === undefined ? null : this.agreementIsSent === '1',
        //   projectAgreementIds: this.projectAgreementIds, 
        //   // isRealNamed: true,
        // },
        data: d,
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded,multipart/form-data",
        },
      };
      this.$api
        .request(options)
        .then((response) => {
          let res = response.data;
          const list = res.items.map((item) => {
            return {
              ...item,
              key: item.personnelId,
              title: item.name,
            };
          });
          if (this.projectPersonalTargets.length > 0) {
            for (let i = 0; i < this.projectPersonalTargets.length; i++) {
              const item = this.projectPersonalTargets[i];
              // list.push(item);
              if (!list.filters(v => v.key === item.key).length) {
                list.push(item);
              }
            }
          }
          this.projectPersonals = list;
          const pagination = { ...this.pagination };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = res.totalCount;
          this.loading = false;
          this.pagination = pagination;
        })
        .catch((e) => {
          // console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 读取项目协议列表
    async getListByProjectId() {
      this.loading = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/GetListByProjectId",
          params: {
            projectId: this.form.projectId,
            isInvalid: false, // 未作废的
          },
        });
        this.projectAgreements = res.map((item) => {
          return {
            key: item.id,
            title: item.name,
            description: item.describe,
            pdfTemplateUrl: item.pdfTemplateUrl,
            agreementTemplateId: item.agreementTemplateId,
          };
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          title: "选择项目", // 显示标题
          url: "/api/services/app/UnnaturalPersonProject/GetSelectPaged", // 数据接口URL
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          this.projectPersonalTargets = [];
          this.projectPersonalTargetKeys = [];
          this.projectAgreementTargetKeys = [];
          this.projectAgreementTargets = [];
          this.form.projectId = data.id;
          this.form.projectName = data.name;
          this.form.projectCode = data.description;
          // console.log(data);
          this.getListByProjectId();
        }
      });
    },
    filterOption(inputValue, option) {
      return option.description.indexOf(inputValue) > -1;
    },
    handleChange(projectAgreementTargetKeys, direction, moveKeys) {
      this.projectAgreementTargetKeys = projectAgreementTargetKeys;
      let list = [];
      let agreementTemplateIds = []
      for (let i = 0; i < this.projectAgreementTargetKeys.length; i++) {
        const key = this.projectAgreementTargetKeys[i];
        const res = this.projectAgreements.find((p) => p.key === key);
        if (res) {
          list.push(res);
          agreementTemplateIds.push(res.agreementTemplateId)
        }
      }
      this.projectAgreementTargets = list;
      this.projectAgreementIds = agreementTemplateIds;
    },
    handleSearch(dir, value) {
      console.log("search:", dir, value);
    },
    next() {
      if (this.current === 0) {
        if (
          this.form.projectId === "" ||
          this.projectAgreementTargetKeys.length === 0
        ) {
          // abp.message.error("请选择项目与协议", "系统提示");
          this.$message.warn("请选择项目与协议");
          return;
        }
        console.log(this.projectAgreementTargetKeys);
        this.getPersonalsByProjectId();
      }
      if (this.current === 1) {
        console.log(this.projectAgreementTargetKeys);
        console.log(this.projectPersonalTargetKeys);
        if (this.projectPersonalTargetKeys.length === 0) {
          this.$message.warn("请选择需要接收的人员");
          return;
        }
      }
      this.current++;
    },
    prev() {
      this.current--;
    },
    agreementIsSentChange(v) {
      this.agreementIsSent = v
    },
    importData() {
      ModalHelper.create(
        ImportForm,
        { id: "" },
        {
          width: "500px",
        }
      ).subscribe((res) => {
        if (res) {
          this.oFile = res;
          this.getPersonalsByProjectId();
        }
      });
    },
    clearFile() {
      this.oFile = null;
      this.$message.info("清空成功");
      this.getPersonalsByProjectId();
    }
  },
};
</script>
<style scoped>
.steps-content {
  margin-top: 20px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  /* background-color: #fafafa; */
  min-height: 200px;
  text-align: center;
  /* padding: 40px 140px; */
  padding: 40px 20px;
}

.steps-action {
  margin-top: 24px;
  text-align: center;
}
.x-ag .ant-transfer-list-content {
  text-align: left;
}
</style>
