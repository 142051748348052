<template>
  <a-spin :spinning="spinning">
    <!-- <div> -->
    <!-- table -->
    <!-- <page-header title="项目人员管理"></page-header> -->

    <!-- <a-card :bordered="false"> -->
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :columns="columns"
      :spinning="spinning"
      :current-page="pageNumber"
      :scroll="{ x: 1000 }"
      :is-full="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table ref="rt" :columns="columns" :table-data="tableData" :total-items="totalItems" :is-full="true"
      :actionsType="actionsType" :current-page="pageNumber" :hide-row-selection="false"
      @emitRefreshData="clearFilterAndRefresh" @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange" @emitShowSizeChange="updateShowSizeChange">
      <!-- <a-form-model
        layout="inline"
        :model="searchFormInline"
        @submit="getData"
        @submit.native.prevent
      > -->
      <!-- <a-form-model-item label="项目"> -->
      <a-row :gutter="8">
        <a-col :span="4">
          <a-input v-model="searchFormInline.ProjectName" placeholder="请选择项目" @click="selectProjectModal">
          </a-input>
          <a-input v-model="searchFormInline.ProjectIds" type="hidden">
          </a-input>
        </a-col>
        <a-col :span="3">
          <a-input v-model.trim="searchFormInline.ProjectCode" :placeholder="l('ProjectHeaderCode')" />
        </a-col>
        <!-- <a-col :span="4">
          <a-input
            v-model.trim="searchFormInline.ProjectName"
            :placeholder="l('ProjectHeaderName')"
          />
        </a-col>-->
        <!-- </a-form-model-item> -->
        <!-- <a-form-model-item label="关键字"> -->
        <a-col :span="3">
          <a-input v-model.trim="searchFormInline.filterText" placeholder="姓名/身份证/手机号">
            <a-icon slot="prefix" type="key" style="color: rgba(0, 0, 0, 0.25)" />
          </a-input>
        </a-col>
        <a-col :span="3">
          <a-input v-model.trim="searchFormInline.ProjectJoiner" placeholder="项目参与人">
            <a-icon slot="prefix" type="key" style="color: rgba(0, 0, 0, 0.25)" />
          </a-input>
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-select style="width: 100%" @change="selectRealNamedChange" placeholder="请选择实名状态"
            v-model="searchFormInline.isRealNamed" allowClear>
            <a-select-option :value="it.value" v-for="(it, i) in realNamedStatusList" :key="i">
              {{ it.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <!-- </a-form-model-item> -->
        <!-- <a-form-model-item> -->
        <a-col :span="4">
          <a-button type="primary" @click="search">
            <a-icon type="search" /> {{ l("Search") }}
          </a-button>
          <!-- </a-form-model-item> -->
          <!-- <a-form-model-item> -->
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>

      </a-row>
      <a-row :gutter="24" style="margin-top:10px">
        <a-col :span="24" align="right">
          <a-button-group>
                <a-button v-if="isGranted('Pages.UnnaturalProjectPersonnelPermissions.ChiHongLeaveImport')"
              @click="chiHongLeaveImportData()">
              <a-icon type="upload" />
              {{ l("赤红离职人员导入") }}
            </a-button>
              <a-button v-if="isGranted('Pages.UnnaturalProjectPersonnelPermissions.ChiHongImport')"
              @click="chiHongImportData()">
              <a-icon type="upload" />
              {{ l("赤红项目人员导入") }}
            </a-button>
            <a-button v-if="isGranted('Pages.UnnaturalProjectPersonnelPermissions.MeituanImport')"
              @click="meiTuanImportData()">
              <a-icon type="upload" />
              {{ l("美团项目人员导入") }}
            </a-button>
            <a-button v-if="isGranted('Pages.UnnaturalProjectPersonnelPermissions.MeituanExport')"
              @click="exportMeituanToExcel()">
              <a-icon type="cloud-download" />
              <span>{{ l("美团协议签署导出") }}</span>
            </a-button>
            <a-button v-if="isGranted('Pages.UnnaturalProjectPersonnelPermissions.WanyooStoreProjectUserExport')"
              @click="exportWanyooStoreToExcel()">
              <a-icon type="cloud-download" />
              <span>{{ l("网鱼网咖项目人员导出") }}</span>
            </a-button>
            <a-button @click="importData()" v-if="isGranted('Pages.UnnaturalProjectPersonnelPermissions.Import')
            ">
              <a-icon type="cloud-upload" />
              {{ l("导入") }}
            </a-button>
            <a-button v-if="isGranted('Pages.UnnaturalProjectPersonnelPermissions.Export')" @click="exportToExcel()">
              <a-icon type="file-excel" />
              <span>{{ l("Export") }}</span>
            </a-button>
            <a-button v-if="isGranted('Pages.UnnaturalProjectPersonnelPermissions.DesensitExport')"
              @click="exportToExcel(1)">
              <a-icon type="download" />
              <span>{{ l("脱敏导出") }}</span>
            </a-button>
            <a-button type="primary" @click="batchSign" v-if="isGranted(
              'Pages.UnnaturalProjectPersonnelPermissions.BatchSendSign')">
              <a-icon type="notification" />
              <span>发起签署</span>
            </a-button>
          </a-button-group>
        </a-col>
      </a-row>
    </ele-table>
    <!-- </a-card> -->

    <a-modal centered :title="inputModalParams.title" :visible="inputModalParams.visible"
      :confirm-loading="inputModalParams.confirmLoading" :cancelText="inputModalParams.cancelText"
      :okText="inputModalParams.okText" :maskClosable="inputModalParams.maskClosable"
      :destroyOnClose="inputModalParams.destroyOnClose" :width="inputModalParams.width" @ok="inputModalParams.confirm"
      @cancel="inputModalParams.cancel">
      <div>
        <a-form :form="form" v-bind="formItemLayout">
          <a-form-item label="通过审核" required>
            <a-switch v-decorator="['isPass', { valuePropName: 'checked' }]" @change="handleChange" />
          </a-form-item>

          <a-form-item label="不通过原因">
            <a-input :disabled="inputModalParams.disabled" type="textarea" v-decorator="[
              'notPassReason',
              {
                rules: [
                  {
                    required: !inputModalParams.disabled,
                    message: '请输入',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>

    <a-modal centered :title="tagLabelModalParams.title" :visible="tagLabelModalParams.visible"
      :confirm-loading="tagLabelModalParams.confirmLoading" :cancelText="tagLabelModalParams.cancelText"
      :okText="tagLabelModalParams.okText" :maskClosable="tagLabelModalParams.maskClosable"
      :destroyOnClose="tagLabelModalParams.destroyOnClose" :width="tagLabelModalParams.width"
      @ok="tagLabelModalParams.confirm" @cancel="tagLabelModalParams.cancel">
      <div>
        <a-form :form="tagLabelModalParams.form" v-bind="formItemLayout">
          <a-form-item label="标签字段">
            <a-input :disabled="tagLabelModalParams.disabled" type="textarea" v-decorator="[
              'lable',
              {
                rules: [
                  {
                    required: !tagLabelModalParams.disabled,
                    message: '请输入标签字段',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </a-spin>
  <!-- </div> -->
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import axios from "axios";
import { fileDownloadService } from "@/shared/utils";
import CreateOrUpdate from "../projectuser/addoredit.vue";
import ImportForm from "../projectuser/importform.vue";
import DetailForm from "../projectuser/details.vue";
import BatchNotificationList from "../projectuser/batchnotification/batchnotification_list.vue";
import BatchSignStep from "./batch-sign-step.vue";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";
import EleTable from "@/components/ele-table";
import PersonnelAgreementList from "../projectuser/personnelagreement/agreementlist";
import ReactModify from "@/components/react-modify";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import SelectPageList from "@/components/linggong/select-page-list";
import MeiTuanImportForm from "../projectuser/meituanimportform.vue"
import ChiHongImportForm from "../projectuser/chihongimportform.vue"
import ChiHongLeaveImportForm from "../projectuser/chihongleaveImportform.vue"
import modalHelper from '@/shared/helpers/modal/modal-helper';

export default {
  mixins: [AppComponentBase],
  name: "projectmodule",
  components: { EleTable },
  data: function () {
    return {
      spinning: false,
      searchFormInline: {
        RealName: undefined,
        PhoneNumber: "",
        IdCard: "",
        ProjectName: "",
        ProjectIds: "",
        ProjectCode: "",
        ProjectJoiner: "",
        projectId: "",
        filterText: "",
        isRealNamed: undefined,
        sorting: "",
        SkipCount: 0,
        MaxResultCount: 50,
      },
      realNamedStatusList: [
        { label: "已实名", value: 1 },
        { label: "未实名", value: 0 },
      ],
      Proxy: null,
      selectedRowKeys: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [1, 1],
      pageSizeOptions: ["10", "20", "30", "40"],
      tableData: [],
      columns: [
        {
          title: this.l("ProjectHeaderCode"),
          dataIndex: "projectCode",
          //sorter: true,
          align: "left",
          width: 200,
          scopedSlots: { customRender: "projectCode" },
        },
        {
          title: this.l("ProjectHeaderName"),
          dataIndex: "projectName",
          // sorter: true,
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "projectName" },
        },

        {
          title: this.l("NameSurname"),
          dataIndex: "name",
          // sorter: true,
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("ProjectUserIdCard"),
          dataIndex: "idCard",
          // sorter: true,
          align: "center",
          width: 150,
          scopedSlots: { customRender: "idCard" },
        },
        {
          title: "身份证失效日期",
          dataIndex: "idCardValidity",
          // sorter: true,
          align: "center",
          width: 200,
          scopedSlots: { customRender: "idCardValidity" },
        },
        {
          title: this.l("手机号"),
          dataIndex: "phoneNumber",
          // sorter: true,
          align: "center",
          width: 200,
          scopedSlots: { customRender: "phoneNumber" },
        },
        {
          title: this.l("银行卡号"),
          dataIndex: "bankCardNo",
          // sorter: true,
          align: "center",
          width: 200,
          scopedSlots: { customRender: "bankCardNo" },
        },
        {
          title: this.l("开户行"),
          dataIndex: "bankName",
          // sorter: true,
          align: "center",
          width: 200,
          scopedSlots: { customRender: "bankName" },
        },
        {
          title: this.l("开户地"),
          dataIndex: "bankArea",
          // sorter: true,
          align: "center",
          width: 200,
          scopedSlots: { customRender: "bankArea" },
        },
        {
          title: this.l("地址"),
          dataIndex: "address",
          // sorter: true,
          align: "center",
          width: 200,
          scopedSlots: { customRender: "address" },
        },
        {
          title: this.l("来源"),
          dataIndex: "source",
          // sorter: true,
          align: "center",
          width: 200,
          scopedSlots: { customRender: "source" },
        },
        // {
        //   title: this.l("ProjectApplyDate"),
        //   dataIndex: "applyDate",
        //   sorter: true,
        //   align: "center",
        //   customRender: function (text, record, index) {
        //     if (text) {
        //       return text.split("T")[0];
        //     }
        //     return "";
        //   },
        //   scopedSlots: { customRender: "applyDate" },
        // },
        // {
        //   title: this.l("StartDateTime"),
        //   dataIndex: "beginDate",
        //   sorter: true,
        //   align: "center",
        //   customRender: function (text, record, index) {
        //     if (text) {
        //       return text.split("T")[0];
        //     }
        //     return "";
        //   },
        //   scopedSlots: { customRender: "beginDate" },
        // },
        // {
        //   title: this.l("EndDateTime"),
        //   dataIndex: "endDate",
        //   sorter: true,
        //   align: "center",
        //   customRender: function (text, record, index) {
        //     if (text) {
        //       return text.split("T")[0];
        //     }
        //     return "";
        //   },
        //   scopedSlots: { customRender: "endDate" },
        // },
        // {
        //   title: this.l("审核状态"),
        //   dataIndex: "auditStatus",
        //   sorter: true,
        //   align: "center",
        //   scopedSlots: { customRender: "auditStatus" },
        // },
        // {
        //   title: this.l("审核原因"),
        //   dataIndex: "reason",
        //   sorter: true,
        //   align: "center",
        //   scopedSlots: { customRender: "reason" },
        // },
        // {
        //     title: this.l("ProjectRemark"),
        //     dataIndex: "remark",
        //     sorter: true,
        //     align: "center",
        //     scopedSlots: { customRender: "remark" }
        // },
        {
          title: this.l("实名认证"),
          dataIndex: "isRealNamed",
          sorter: true,
          align: "center",
          type: 'tag',
          width: 100,
          scopedSlots: { customRender: "isRealNamed" },
          customRender: function (text, record, index) {
            return text ? "已实名" : "未实名";
          },
        },
        {
          title: this.l("员工进入系统时间"),
          dataIndex: "userCreationTime",
          sorter: true,
          align: "center",
          type: "custom",
          width: 150,
          scopedSlots: { customRender: "userCreationTime" },
          customRender: function (text, record, index) {
            if (text && text != null) {
              return text.replace("T", " ").substring(0, 19);
            }
            return "";
          },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          align: "center",
          fixed: "right",
          width: 120,
          scopedSlots: { customRender: "actions" },
        },
      ],
      // modal
      commonService: null,
      form: this.$form.createForm(this, { name: "coordinated" }),
      form1: this.$form.createForm(this, { name: "coordinated" }),
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "审核",
        cancelText: "取消",
        okText: "确认",
        form: {},
        id: null,
        disabled: false,
        cancelText: "取消",
        okText: "确认",
        confirm: () => { },
        cancel: () => {
          this.inputModalParams.visible = false;
        },
      },
      uploadFileUrl:
        this.$apiUrl +
        "/api/services/app/UnnaturalPersonProjectPersonnel/Import",
      uploadfileList: [],
      uploadHeaders: {},
      tagLabelModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "标签字段",
        cancelText: "取消",
        okText: "确认",
        form: this.$form.createForm(this, { name: "coordinated" }),
        id: null,
        disabled: false,
        cancelText: "取消",
        okText: "确认",
        confirm: () => { },
        cancel: () => {
          this.tagLabelModalParams.visible = false;
        },
      },
    };
  },
  methods: {
    selectProjectModal () {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/UnnaturalPersonProject/GetSelectPaged", // 数据接口URL
          searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        console.log(res);
        const { success, data } = res;
        if (success) {
          //   message.success("succed");
          //   console.log(data);
          let projectIds = "";
          let projectNames = "";
          for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            if (i === data.length - 1) {
              projectIds += item.id;
              projectNames += item.name;
              continue;
            }
            projectIds += item.id + ",";
            projectNames += item.name + ",";
          }
          this.searchFormInline.ProjectIds = projectIds; //data.id;
          this.searchFormInline.ProjectName = projectNames; //data.name;
          this.getData();
          // this.getTaskByProjectId(0, data.id);
        }
      });
    },
    uploadChange (info) {
      this.spinning = true;
      let fileList = [...info.fileList];
      fileList = fileList.slice(-2);
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.uploadfileList = fileList;
      if (info.file.status === "done") {
        this.spinning = false;
        this.$notification["success"]({
          message: "文件上传成功",
        });
        this.refreshGoFirstPage();
      } else if (info.file.status === "error") {
        this.$notification["error"]({
          message: "文件上传失败",
        });
        this.spinning = false;
      }
    },
    async createReactModify (item, type) {
      let list = [];
      const orginListLength = list.length;
      let reqParams = [];
      if (item && item.id) {
        // console.log(item)
        reqParams.push({ key: "id", value: item.id });
      }
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [...reqParams],
            reqUrl: {
              getById:
                "/api/services/app/UnnaturalPersonProjectPersonnel/GetExtendInfoById",
              save: "/api/services/app/UnnaturalPersonProjectPersonnel/SaveExtendInfo",
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              res.formItems.map((_item, index) => {
                switch (_item.itemType) {
                  case "Text":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "commonInput",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        { max: _item.maxLength, message: this.l("MaxLength") },
                      ],
                      span: 24,
                      changeFn: () => { },
                    });
                    break;
                  case "Textarea":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "textarea",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        { max: _item.maxLength, message: this.l("MaxLength") },
                      ],
                      span: 24,
                      changeFn: () => { },
                    });
                    break;
                  case "Number":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "numberInput",
                      disabled: false,
                      required: _item.isRequire,
                      max: _item.maxLength,
                      min: 0,
                      key: _item.key,
                      rules: [],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => { },
                    });
                    break;
                  case "Select":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "commonSelect",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: "",
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => { },
                    });
                    break;
                  case "Checkbox":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "checkbox",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: null,
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => { },
                    });
                    break;
                  case "Radio":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "radio",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: null,
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => { },
                    });
                    break;
                  case "Date":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "datePicker",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        {
                          type: "object",
                          required: _item.isRequire,
                          message: _item.remark,
                        },
                      ],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => { },
                    });
                    break;
                  case "Upload":
                    list.push({
                      label: _item.name,
                      value: [],
                      type: "upload",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [],
                      subdirName: environment.production ? "abpProd" : "abpDev",
                      uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
                      limitedLength: _item.maxLength,
                      span: 24,
                      changeFn: (info) => {
                        let fileList = [...info.fileList];
                        fileList = fileList.map((file) => {
                          if (file.response) {
                            file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
                          }
                          return file;
                        });
                        list[_item.sort + orginListLength].value = fileList;
                      },
                    });
                    break;
                  case "DateRange":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "rangeDatePicker",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        {
                          type: "array",
                          required: _item.isRequire,
                          message: _item.remark,
                        },
                      ],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => { },
                    });
                    break;
                  default:
                    throw new Error("暂无匹配项目");
                }
              });

              // console.log(list);

              if (!item) {
                form.setFieldsValue({
                  ProjectCode: await this._getProjectCode(),
                });
              } else {
                form.setFieldsValue({
                  ProjectName: item.projectName,
                  ProjectType: item.projectType,
                  OrganizationUnitId: item.organizationUnitId,
                  ProjectTime: [item.beginDate, item.endDate],
                  CustomerName: item.customerName,
                  ProjectCode: item.projectCode,
                });
              }

              if (res.results && Object.keys(res.results).length !== 0) {
                const oResult = res.results;
                let opts = {};
                list.map((it) => {
                  if (it.key) {
                    let k = it.key;
                    if (it.type === "upload") {
                      if (oResult[k].value.indexOf(",") !== -1) {
                        let arr = oResult[k].value.split(",");
                        arr.map((val, i) => {
                          let n = val.split("/");
                          it.value.push({
                            uid: `-${i + 1}`,
                            url: `${AppConsts.uploadBaseUrl}${val}`,
                            name: n[n.length - 1],
                            response: { data: { Path: val } },
                            status: "done",
                          });
                        });
                      } else {
                        if (oResult[k].value && oResult[k].value.length) {
                          let n = oResult[k].value.split("/");
                          it.value.push({
                            uid: `-1`,
                            url: `${AppConsts.uploadBaseUrl}${res.pictureUrls}`,
                            name: n[n.length - 1],
                            response: { data: { Path: res.pictureUrls } },
                            status: "done",
                          });
                        }
                      }
                    } else if (it.type === "rangeDatePicker") {
                      if (oResult[k]) {
                        opts[`${k}`] =
                          oResult[k].value.indexOf("至") === -1
                            ? [oResult[k].value]
                            : oResult[k].value.split("至");
                      }
                    } else if (it.type === "datePicker") {
                      if (oResult[k] && oResult[k].value != '') {
                        opts[`${k}`] = moment(oResult[k].value, "YYYY-MM-DD");
                      }
                    } else if (it.type === "checkbox") {
                      opts[`${k}`] =
                        oResult[k].value.indexOf(",") === -1
                          ? [oResult[k].value]
                          : oResult[k].value.split(",");
                    } else {
                      opts[`${k}`] = oResult[k].value;
                    }
                  }
                });
                // console.log(opts);
                this.$nextTick(() => {
                  form.setFieldsValue({ ...opts });
                });
              }

              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              let fileUrl = [];
              for (let i = 0; i < list.length; i++) {
                if (list[i].type === "upload" && list[i].required) {
                  if (!list[i].value.length) {
                    this.$message.info(`请上传${list[i].label}的附件资源`);
                    break;
                  }
                }
              }

              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);

              let dynamicList = list.filter((item) => item.key);
              let _results = [];
              // console.log(dynamicList);

              dynamicList.map((_item) => {
                // console.log(_item);
                if (
                  _item.type === "commonInput" ||
                  _item.type === "numberInput" ||
                  _item.type === "textarea" ||
                  _item.type === "radio" ||
                  _item.type === "commonSelect"
                ) {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value: values[`${_item.value}`],
                    },
                  });
                  // } else if (_item.type === "datePicker" && _item.isRequire) {
                } else if (_item.type === "datePicker") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value: values[`${_item.value}`].format("YYYY-MM-DD"),
                      // value: values[`${_item.value}`],
                    },
                  });
                } else if (
                  _item.type === "rangeDatePicker"
                  //  && _item.isRequire
                ) {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        values[`${_item.value}`][0] +
                        "至" +
                        values[`${_item.value}`][1],
                    },
                  });
                } else if (_item.type === "checkbox") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        values[`${_item.value}`].length === 1
                          ? values[`${_item.value}`][0]
                          : values[`${_item.value}`].join(","),
                    },
                  });
                } else if (_item.type === "upload") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        _item.value.length === 1
                          ? `${_item.value[0].response.data.Path}`
                          : _item.value
                            .map((v) => {
                              return v.response.data.Path;
                            })
                            .join(","),
                    },
                  });
                }
              });

              let submitData = {
                projectPersonnelId: item && item.id ? item.id : null,
                results: _results,
              };
              // console.log("submitData:", submitData);
              // return false;
              try {
                let res = await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: { ...submitData },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
    initActionsType () {
      let _this = this;
      let obj = {
        type: "project-user",
        isShow: true,
        fns: {
          isShow: true,
          dropList: [
            // {
            //   granted: true,
            //   name: this.l("Edit"),
            //   icon: "edit",
            //   fn: (data) => {
            //     _this.createOrEdit(data.id);
            //   },
            // },
            // {
            //   granted: true,
            //   name: this.l("审核"),
            //   icon: "profile",
            //   fn: (data) => {
            //     _this._audit(data.id);
            //   },
            // },
            {
              granted: this.isGranted(
                "Pages.UnnaturalProjectPersonnelPermissions.Sign"
              ),
              name: this.l("签约"),
              icon: "notification",
              fn: (data) => {
                _this._projectPersonnelAgreementModal(data);
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalProjectPersonnelPermissions.ViewDetail"
              ),
              name: this.l("查看详情"),
              icon: "profile",
              fn: (data) => {
                _this.lookPersonDetail(data.personnelId);
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalProjectPersonnelPermissions.ExtendInfo"
              ),
              name: this.l("扩展信息"),
              icon: "profile",
              fn: (data) => {
                _this.createReactModify(data, "edit");
              },
            },
            {
              granted: true,
              name: this.l("标签字段"),
              icon: "profile",
              fn: (data) => {
                _this.tagLabelModal(data.id, "edit");
              },
            },
          ],
          // delete: {
          //   granted: true,
          //   name: this.l("Delete"),
          //   fn: (data) => {
          //     _this.deleteItem(data.id);
          //   },
          // },
        },
      };
      this.actionsType = obj;
    },
    // 排序更新data
    updateSortData (newV) {
      let { columnKey, order } = newV;
      this.searchFormInline.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange (newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    updateShowSizeChange (newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    // table选择事件
    updateSelectChange (newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    /**
    * 导出为excel
    */
    async exportToExcel (type) {
      this.spinning = true;
      try {
        var url = type && type === 1 ? '/api/services/app/UnnaturalPersonProjectPersonnel/ExportByDesensit' : '/api/services/app/UnnaturalPersonProjectPersonnel/GetPageExcelFile'
        let res = await this.commonService.post({
          url: url,
          params: {
            ...this.searchFormInline,
            projectName: "",
            isRealNamed:
              this.searchFormInline.isRealNamed === undefined
                ? ""
                : this.searchFormInline.isRealNamed,
          },
        });
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async exportWanyooStoreToExcel () {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectPersonnel/GetWanyooStorePageExcelFile",
          params: {
            ...this.searchFormInline,
            projectName: "",
            isRealNamed:
              this.searchFormInline.isRealNamed === undefined
                ? ""
                : this.searchFormInline.isRealNamed,
          },
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async exportMeituanToExcel () {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectPersonnel/GetMeituanPageExcelFile",
          params: {
            ...this.searchFormInline,
            projectName: "",
            isRealNamed:
              this.searchFormInline.isRealNamed === undefined
                ? ""
                : this.searchFormInline.isRealNamed,
          },
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh () {
      this.refreshGoFirstPage();
    },
    // 分页事件
    showTotalFun () {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    handleChange (value) {
      this.inputModalParams.disabled = value ? true : false;
    },
    _audit (idParams) {
      let _this = this;
      this.inputModalParams.title = "审核";
      this.inputModalParams.confirm = function () {
        _this.form.validateFields(async (err, values) => {
          if (!err) {
            // console.log("Received values of form: ", values);
            _this.inputModalParams.confirmLoading = true;
            try {
              let _res = await _this.commonService.post({
                url: "/api/services/app/ProjectUser/Audit",
                params: {
                  notPassReason: values.notPassReason,
                  isPass: values.isPass,
                  id: idParams,
                },
              });
              _this.inputModalParams.confirmLoading = false;
              _this.inputModalParams.visible = false;
              _this.getData();
              _this.$notification["success"]({
                message: this.l("操作成功"),
              });
            } catch (error) {
              _this.inputModalParams.confirmLoading = false;
            }
          }
        });
      };
      this.inputModalParams.visible = true;
    },
    selectRealNamedChange (value) {
      this.searchFormInline.isRealNamed = value;
    },
    async tagLabelModal (unnaturalPersonnelId) {
      let _this = this;
      this.tagLabelModalParams.visible = true;
      this.tagLabelModalParams.title = "标签字段";
      const label = await this._queryLabel(unnaturalPersonnelId);
      if (label) {
        this.tagLabelModalParams.form.setFieldsValue({ lable: label });
      }
      this.tagLabelModalParams.confirm = function () {
        _this.tagLabelModalParams.form.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.tagLabelModalParams.confirmLoading = true;
            try {
              let _res = await _this.commonService.put({
                url: "/api/services/app/UnnaturalPersonProjectPersonnel/UpdateLabel",
                params: {
                  unnaturalPersonnelId: unnaturalPersonnelId,
                  lable: values.lable,
                },
              });
              _this.tagLabelModalParams.confirmLoading = false;
              _this.tagLabelModalParams.visible = false;
              _this.getData();
              // _this.$notification["success"]({
              //   message: this.l("操作成功")
              // });
              _this.$notification.success({
                message: "提示",
                description: `操作成功`,
              });
            } catch (error) {
              _this.tagLabelModalParams.confirmLoading = false;
            }
          }
        });
      };
    },
    async _queryLabel (unnaturalPersonnelId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectPersonnel/QueryLabel",
          params: { unnaturalPersonnelId },
        });
        this.spinning = false;
        return res;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    _projectPersonnelAgreementModal (item) {
      // console.log("item" + JSON.stringify(item));
      let obj = {};
      obj.userId = item.personnelId;
      obj.projectId = item.projectId;
      ModalHelper.create(
        PersonnelAgreementList,
        { personnelAgreementItem: obj },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    _auditRange () {
      if (!this.selectedRowKeys.length) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return;
      }
      let _this = this;
      this.inputModalParams.title = "批量审核";
      this.inputModalParams.confirm = function () {
        _this.form.validateFields(async (err, values) => {
          if (!err) {
            // console.log("Received values of form: ", values);
            _this.inputModalParams.confirmLoading = true;
            try {
              let _res = await _this.commonService.post({
                url: "/api/services/app/ProjectUser/AuditRange",
                params: {
                  notPassReason: values.notPassReason,
                  isPass: values.isPass,
                  ids: _this.selectedRowKeys,
                },
              });
              _this.inputModalParams.confirmLoading = false;
              _this.inputModalParams.visible = false;
              _this.selectedRowKeys = [];
              _this.refreshGoFirstPage();
              _this.$notification["success"]({
                message: this.l("操作成功"),
              });
            } catch (error) {
              _this.inputModalParams.confirmLoading = false;
            }
          }
        });
      };
      this.inputModalParams.visible = true;
    },
    search () {
      this.pageNumber = 1;
      this.searchFormInline.SkipCount = 0;
      this.getData();
    },
    getData () {
      var that = this;
      //this.searchFormInline.projectId = this.$route.quePagery.id;
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      })
      let url =
        this.$apiUrl +
        "/api/services/app/UnnaturalPersonProjectPersonnel/GetPaged";
      let options = {
        data: {
          ...this.searchFormInline,
          projectName: "",
          isRealNamed:
            this.searchFormInline.isRealNamed === undefined
              ? ""
              : this.searchFormInline.isRealNamed,
        },
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      };
      // console.log(options);
      this.$api
        .request(options)
        .then((response) => {
          let res = response.data;
          this.tableData = res.items.map((item) => {
            return {
              ...item,
              auditStatus:
                item.auditStatus === 0
                  ? "待审核"
                  : item.auditStatus === 1
                    ? "通过"
                    : "不通过",
              isRealNamed: item.isRealNamed ? "已实名" : "未实名",
              isRealNamedColor: item.isRealNamed ? '#87d068' : '#f50',
              idCardValidity: that.idCardValidityFormat(item.idCardValidity)
            };
          });
          this.totalItems = res.totalCount;
          this.totalPages = Math.ceil(
            res.totalCount / this.searchFormInline.MaxResultCount
          );
          this.pagerange = [
            (this.pageNumber - 1) * this.searchFormInline.MaxResultCount + 1,
            this.pageNumber * this.searchFormInline.MaxResultCount,
          ];
        })
        .catch((e) => {
          console.error(e);
          abp.message.warn(this.l("DefaultErrorMessage"));
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    idCardValidityFormat (value) {
      if (!value || value == '0001-01-01T00:00:00') return ''
      if (value == '2199-01-01T00:00:00') return '长期'
      return moment(value).format("YYYY/MM/DD")
    },
    refreshGoFirstPage () {
      this.searchFormInline.ProjectName = "";
      this.searchFormInline.ProjectCode = "";
      // this.searchFormInline.NameSurname = "";
      this.searchFormInline.isRealNamed = undefined;
      this.searchFormInline.PhoneNumber = "";
      this.searchFormInline.IdCard = "";
      this.searchFormInline.sorting = "";
      this.searchFormInline.ProjectIds = "";
      this.searchFormInline.ProjectName = "";
      // this.searchFormInline.ProjectUserPhone = "";
      this.searchFormInline.ProjectJoiner = "";
      this.searchFormInline.filterText = "";
      this.pageNumber = 1;
      this.searchFormInline.SkipCount = 0;
      this.getData();
    },
    createOrEdit (id) {
      ModalHelper.create(
        CreateOrUpdate,
        { id: id },
        {
          width: "500px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    batchDelete () {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return;
      }
      this.message.confirm(
        this.l("ConfirmDeleteXItemsWarningMessage", selectCount),
        (res) => {
          if (res) {
            const ids = _.map(this.selectedRowKeys);
            this.spinning = true;
            let url =
              this.$apiUrl + "/api/services/app/ProjectUser/BatchDelete";
            let options = {
              data: ids,
              method: "POST",
              url: url,
              headers: {
                "Content-Type": "application/json-patch+json",
              },
            };
            this.$api
              .request(options)
              .finally(() => {
                this.spinning = false;
              })
              .then(() => {
                this.selectedRowKeys = [];
                this.refreshGoFirstPage();
                this.$notification["success"]({
                  message: this.l("SuccessfullyDeleted"),
                });
              });
          }
        }
      );
    },
    onChange (page, pageSize) {
      this.pageNumber = page;
      // this.searchFormInline.SkipCount = page;
      this.searchFormInline.SkipCount =
        (page - 1) * this.searchFormInline.MaxResultCount;
      this.getData();
    },
    showSizeChange (current, size) {
      this.pageNumber = 1;
      this.searchFormInline.MaxResultCount = size;
      this.getData();
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    deleteItem (id) {
      this.spinning = true;
      let url = this.$apiUrl + "/api/services/app/ProjectUser/BatchDelete";
      let options = {
        data: [id],
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      };
      this.$api
        .request(options)
        .finally(() => {
          this.spinning = false;
        })
        .then(() => {
          this.refreshGoFirstPage();
          this.$notification["success"]({
            message: this.l("SuccessfullyDeleted"),
          });
        });
    },
    restCheckStatus () {
      this.selectedRowKeys = [];
    },
    showTotalFun () {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    importData () {
      ModalHelper.create(
        ImportForm,
        { id: "" },
        {
          width: "500px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    meiTuanImportData () {
      ModalHelper.create(
        MeiTuanImportForm,
        { id: "" },
        {
          width: "500px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
      chiHongImportData () {
      ModalHelper.create(
        ChiHongImportForm,
        { id: "" },
        {
          width: "500px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
     chiHongLeaveImportData () {
      ModalHelper.create(
        ChiHongLeaveImportForm,
        { id: "" },
        {
          width: "500px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    batchSign () {
      ModalHelper.create(
        BatchSignStep,
        {
          id: "xxx",
        },
        {
          width: "1200px",
          height: "860px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    batchNotification () {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return;
      }
      let projectIds = this.selectedRows.map((item) => {
        return item.projectId;
      });
      let projectArr = projectIds.filter(function (item, index, projectIds) {
        return projectIds.indexOf(item, 0) === index;
      });
      if (projectArr != null && projectArr.length != 1) {
        abp.message.warn(this.l("同一个项目下的用户才可以进行批量签署"));
        return false;
      }
      let userIds = this.selectedRows.map((item) => {
        return item.personnelId;
      });
      let projectId = projectIds[0];
      ModalHelper.create(
        BatchNotificationList,
        { selectUserIds: userIds, projectId: projectId },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    returnPage () {
      this.$router.push("/app/unnaturalperson/project");
    },
    lookPersonDetail (id) {
      ModalHelper.create(
        DetailForm,
        { id: id },
        {
          width: "500px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
  },
  created () {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    if (this.$route.query.id) {
      // console.log(this.$route.query.id);
      this.getData();
    } else {
      this.getData();
    }
    this.initActionsType();
    Object.assign(this.uploadHeaders, {
      Authorization: "Bearer " + abp.auth.getToken(),
    });
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}

._return {
  text-align: right;
  background: white;
  padding: 9px;
}
</style>
