<template>
  <a-spin :spinning="spinning">
    <!-- 标题 -->
    <div class="modal-header">
      <div class="modal-title">
        <span>{{ l("人员详情") }}</span>
      </div>
    </div>
    <!-- 身份证信息 -->
    <h3 class="_title">{{ l("身份证信息") }}</h3>
    <a-row :gutter="8" class="_row">
      <a-col class="gutter-row" :span="4">
        <div class="gutter-box">{{ l("姓名") }}:</div>
      </a-col>
      <a-col class="gutter-row" :span="20">
        <div class="gutter-box">{{ userData.realName }}</div>
      </a-col>
    </a-row>
    <a-row :gutter="8" class="_row">
      <a-col class="gutter-row" :span="4">
        <div class="gutter-box">{{ l("证件类型") }}:</div>
      </a-col>
      <a-col class="gutter-row" :span="20">
        <div class="gutter-box">{{ userData.idTypeName }}</div>
      </a-col>
    </a-row>
    <a-row :gutter="8" class="_row">
      <a-col class="gutter-row" :span="4">
        <div class="gutter-box">{{ l("证件号码") }}:</div>
      </a-col>
      <a-col class="gutter-row" :span="20">
        <div class="gutter-box">{{ userData.idCard }}</div>
      </a-col>
    </a-row>
    <a-row :gutter="8" class="_row">
      <a-col class="gutter-row" :span="4">
        <div class="gutter-box">{{ l("证件有效期") }}:</div>
      </a-col>
      <a-col class="gutter-row" :span="20">
        <div class="gutter-box">{{ userData.idCardValidity }}</div>
      </a-col>
    </a-row>
    <a-row :gutter="8" class="_row" style="border-bottom: 1px solid #888">
      <a-col class="gutter-row" :span="4">
        <div class="gutter-box">{{ l("籍贯地址") }}:</div>
      </a-col>
      <a-col class="gutter-row" :span="20">
        <div class="gutter-box">{{ userData.address }}</div>
      </a-col>
    </a-row>
    <!-- 证件照 -->
    <br />
    <h3 class="_title">{{ l("证件照") }}</h3>
    <a-row :gutter="8">
      <a-col class="gutter-row" :span="4">
        <div class="gutter-box ">{{ l("身份证正面照片") }}:</div>
      </a-col>
      <a-col class="gutter-row" :span="20">
        <div class="gutter-box"><img :src="userData.idCardPhotoFront" class="_img" /></div>
      </a-col>
    </a-row>
    <a-row :gutter="8" style="margin-top:20px">
      <a-col class="gutter-row" :span="4">
        <div class="gutter-box ">{{ l("身份证反面照片") }}:</div>
      </a-col>
      <a-col class="gutter-row" :span="20">
        <div class="gutter-box"><img :src="userData.idCardPhotoVerso" class="_img" /></div>
      </a-col>
    </a-row>

    <!-- 按钮 -->
    <div class="modal-footer">
      <a-button :disabled="saving" @click="close()" type="button">
        <a-icon type="close-circle" />
        {{ l("Cancel") }}
      </a-button>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import moment from "moment";

export default {
  name: "details",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      spinning: false,
      commonService: null,
      userData: {},
      idCardPhotoFront: "",
      idCardPhotoVerso: ""
    };
  },
  created() {
    console.log(this);
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.fullData(); // 模态框必须,填充数据到data字段
    this.getData();
  },
  methods: {
    getData() {
      this._getPersonDetail();
    },
    async _getPersonDetail() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Personnel/GetPersonnelById",
          params: {
            id: this.id,
          },
        });
        console.log(AppConsts.uploadBaseUrl);
        res.idCardPhotoFront =
          AppConsts.uploadBaseUrl + res.idCardPhotoFront;
        res.idCardPhotoVerso =
          AppConsts.uploadBaseUrl + res.idCardPhotoVerso;
        if (res.idCardValidity && res.idCardValidity != '0001-01-01T00:00:00'&&res.idCardValidity !='2199-01-01T00:00:00') {
          res.idCardValidity = moment(res.idCardValidity).format("YYYY/MM/DD")
        } else if (res.idCardValidity == '2199-01-01T00:00:00') {
          res.idCardValidity = '长期'
        } else {
          res.idCardValidity = '暂无'
        }
        console.log(res);
        this.userData = res;

        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    // 时间
    // getExecutionTime() {
    //   return (
    //     moment(this.auditLog.executionTime).fromNow() +
    //     " (" +
    //     moment(this.auditLog.executionTime).format("YYYY-MM-DD HH:mm:ss") +
    //     ")"
    //   );
    // },
  },
};
</script>

<style scoped>
._title {
  font-size: 20px;
  font-weight: bold;
}

._row {
  border-top: 1px solid #888;
  border-left: 1px solid #888;
  border-right: 1px solid #888;
  padding: 10px;
  font-size: 16px;
}

._row:last-child {
  border-bottom: 1px solid #444;
}

._img {
  width: 400px;
  height: 200px;
}
</style>
