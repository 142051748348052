var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[(_vm.id)?_c('span',[_vm._v(_vm._s(_vm.l('Edit')))]):_vm._e(),(!_vm.id)?_c('span',[_vm._v(_vm._s(_vm.l('Create')))]):_vm._e()])]),_c('a-form',{attrs:{"form":_vm.form,"layout":"horizontal"},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('ProjectHeaderName')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['ProjectId',{ 
                            rules: [
                                {
                                    required: true,
                                    message: _vm.l('RequiredProjectName'),
                                }
                            ]
                        }]),expression:"['ProjectId',{ \n                            rules: [\n                                {\n                                    required: true,\n                                    message: l('RequiredProjectName'),\n                                }\n                            ]\n                        }]"}],attrs:{"placeholder":_vm.l('ProjectHeaderName'),"disabled":!!_vm.id === true},on:{"change":_vm.ProjectChange}},_vm._l((_vm.ProjectData),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.projectName))])}),1)],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('ProjectHeaderCode')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'ProjectCode',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: _vm.l('RequiredProjectCode'),
                                    }
                                ],
                            } ]),expression:"[\n                            'ProjectCode',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: l('RequiredProjectCode'),\n                                    }\n                                ],\n                            },\n                            ]"}],attrs:{"disabled":"","placeholder":_vm.l('ProjectHeaderCode')}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('NameSurname')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['UserId',{ 
                            rules: [
                                {
                                    required: true,
                                    message: _vm.l('RequiredProjectUser'),
                                }
                            ]    
                        }]),expression:"['UserId',{ \n                            rules: [\n                                {\n                                    required: true,\n                                    message: l('RequiredProjectUser'),\n                                }\n                            ]    \n                        }]"}],attrs:{"placeholder":_vm.l('NameSurname'),"disabled":!!_vm.id === true},on:{"change":_vm.UserChange}},_vm._l((_vm.UserData),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.realName))])}),1)],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('ProjectUserIdCard')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['icCard',
                                    { 
                                        rules: [] 
                                    }]),expression:"['icCard',\n                                    { \n                                        rules: [] \n                                    }]"}],attrs:{"disabled":"","placeholder":_vm.l('ProjectUserIdCard')}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('ProjectUserPhone')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['Phone',{ rules: [] }]),expression:"['Phone',{ rules: [] }]"}],attrs:{"disabled":"","placeholder":_vm.l('ProjectUserPhone')}})],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('ProjectHeaderTime')}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['ProjectTime', 
                    { 
                        rules: [
                            {
                                required: true,
                                message: _vm.l('RequiredProjectTime')
                            }
                        ]
                    }]),expression:"['ProjectTime', \n                    { \n                        rules: [\n                            {\n                                required: true,\n                                message: l('RequiredProjectTime')\n                            }\n                        ]\n                    }]"}],attrs:{"placeholder":['开始日期', '结束日期'],"valueFormat":"YYYY-MM-DD"},scopedSlots:_vm._u([{key:"dateRender",fn:function(current){return [_c('div',{staticClass:"ant-calendar-date"},[_vm._v(" "+_vm._s(current.date())+" ")])]}}])})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"24"}},[_c('a-form-item',{attrs:{"label":_vm.l('ProjectRemark')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['Remark',{ rules: [] }]),expression:"['Remark',{ rules: [] }]"}],attrs:{"auto-size":{ minRows: 2, maxRows: 6 },"placeholder":_vm.l('ProjectRemark')}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"24"}},[_c('a-form-item',{staticClass:"btn--container",staticStyle:{"text-align":"center"}},[_c('a-button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.l('Save'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }