<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>{{ l("项目人员导入") }}</span>
      </div>
    </div>
    <a-row :gutter="16" class="btn--container">
      <a-col class="gutter-row" :span="12" style="color: red; font-size: 20px">
        <!-- <a-button type="primary" @click="testUpload()">
          <a-icon type="download" />
          {{ l("上传文件") }}
        </a-button> -->
        温馨提示：先下载模板再导入项目人员
      </a-col>
      <a-col class="gutter-row" :span="12" style="text-align: right">
        <a-button @click="downLoad()">
          <a-icon type="download" />
          {{ l("下载模板") }}
        </a-button>
      </a-col>
    </a-row>
    <a-row :gutter="16" class="upload--container">
      <a-col class="gutter-row" :span="24">
        <a-upload-dragger
          ref="uploadExcel"
          name="file"
          :multiple="false"
          :action="importFromExcelUrl"
          accept=".xls,.xlsx"
          :headers="uploadHeaders"
          :before-upload="beforeUpload"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">将文件拖到此处或点击上传</p>
          <p class="ant-upload-hint">只支持.xls,.xlsx文件格式上传。</p>
        </a-upload-dragger>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { setInterval, setTimeout } from "timers";
import { AppConsts } from "@/abpPro/AppConsts";
import { fileDownloadService } from "@/shared/utils";
import { CommonServiceProxy } from "@/shared/common-service";

export default {
  mixins: [ModalComponentBase],
  name: "import-form",
  data() {
    return {
      spinning: false,
      fileList: [],
      commonService: null,
      _fileDownloadService: "",
      importFromExcelUrl:
        AppConsts.remoteServiceBaseUrl +
        "/api/services/app/UnnaturalPersonProjectPersonnel/Import",
      uploadHeaders: {},
    };
  },
  components: {},
  beforeCreate() {},
  created() {
    this._fileDownloadService = fileDownloadService;
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    Object.assign(this.uploadHeaders, {
      Authorization: "Bearer " + abp.auth.getToken(),
    });
  },
  mounted() {},
  methods: {
    //限制文件上传大小
    beforeUpload(file) {
      this.success(file);
      return false;
    },
    //点击下载模板
    downLoad() {
      window.location.href = '/fileTemplete/项目人员筛选模版.xlsx';
      // this._fileDownloadService.downloadTemplateFile(
      //   "ImportUsersSampleFile.xls"
      // );
    },
    testUpload() {},
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin: 0 20px !important;
}
.upload--container {
  margin: 20px !important;
}
</style>
